import React from "react"
import Masonry from "react-masonry-css"

// export default function Masonry({ children }) {
//   return <div className="columns-1 md:columns-2 lg:columns-3 gap-8">{children}</div>
//   // since the gap is 8, chilldren should have padding: py-4
// }

export default function PhotoMasonry({ children }) {
  const breakpointColumnsObj = {
    default: 3,
    1280: 3,
    1024: 2,
    768: 1
  };
  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="flex w-auto -ml-4"
      columnClassName="pl-4"
    >
      {children}
    </Masonry>
  )
  // since the gap is 8, chilldren should have padding: py-4
}
