import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import { getImage } from "gatsby-plugin-image"
import Hoverimage from "../components/Hoverimage"
import PhotoMasonry from "../components/Masonry"
import Heading from "../components/Heading"
const pageQuery = graphql`
  query PsychologyArchitectures {
    allGraphCmsPsychologyArchitecture(sort: {fields: order_id, order: DESC}) {
      nodes {
        heading
        order_id
        remoteId
        images {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`
export default function PsychologyArchitecture() {
  const {
    allGraphCmsPsychologyArchitecture: { nodes: projects },
  } = useStaticQuery(pageQuery)
  return (
    <Layout title="Katharina Kohler | Psychology + Architecture" description="Katharina Kohler - M.Sc. psychologist and artist, architectural psychology, Berlin, Germany">
      <Heading>Psychology
        <br />+ Architecture
      </Heading>
      <div className="flex justify-center">
        <PhotoMasonry>
          {projects.map(({ heading, remoteId, ...project }) => (
            <Hoverimage
              key={remoteId}
              title={heading}
              image={getImage(project.images[0].localFile)}
              to={"/psychology_architecture/" + remoteId}
              className="mb-4"
            />
          ))}
        </PhotoMasonry>
      </div>
    </Layout>
  )
}
