import { Link } from "gatsby"
import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
export default function Hoverimage(props) {
  return (
    <Link to={props.to}>
      <div className="relative">
        <div className="absolute inset-0 z-10 bg-flieder text-center flex items-center justify-center opacity-0 hover:opacity-100 bg-opacity-80 duration-200">
          <span className="font-bold p-8 text-slate-900 text-center text-xl">
            {props.title}
          </span>
        </div>
        <div className={"relative w-90 " + (props.className ? props.className : "")}>
          <div className="flex flex-wrap content-center">
            <GatsbyImage
              image={props.image}
              alt={props.title}
              className={"select-none " +
                (props.height
                  ? "h-" + props.height + " "
                  : "") + (props.width
                  ? "h-" + props.width + " "
                  : "")
              }
            />
          </div>
        </div>
      </div>
    </Link>
  )
}
